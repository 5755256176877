<template>
  <div class="home">
    <Services/>
    <Numbers/>
    <AboutUs/>
  </div>
</template>

<script>
import AboutUs from '@/components/home/AboutUs.vue'
import Services from '@/components/home/Services.vue'
import Numbers from '@/components/home/Numbers.vue'

export default {
  name: 'Home',
  components: {
    AboutUs,
    Services,
    Numbers
  }
}
</script>
