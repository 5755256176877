
































import Vue from 'vue'
import store from '../../store/index'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Products',
  data () {
    return {
      noHeader: false
    }
  },
  methods: {
  },
  mounted () {
    store.dispatch('getProducts')
  },
  computed: {
    ...mapGetters({
      productsData: 'getProductsData'
    })
  }
})
