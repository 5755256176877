import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import AboutUsView from '../views/AboutUsView.vue'
import ContactView from '../views/ContactView.vue'
import ServicesView from '../views/ServicesView.vue'
import ProductsView from '../views/ProductsView.vue'
import PrivacyView from '../views/PrivacyView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/aboutus',
    name: 'AboutUsView',
    component: AboutUsView
  },
  {
    path: '/contact/:productId?',
    name: 'ContactView',
    component: ContactView
  },
  {
    path: '/services',
    name: 'ServicesView',
    component: ServicesView
  },
  {
    path: '/products',
    name: 'ProductsView',
    component: ProductsView
  },
  {
    path: '/privacy',
    name: 'PrivacyView',
    component: PrivacyView
  }
  // {
  // path: '/products',
  // name: 'Products',
  // route level code-splitting
  // this generates a separate chunk (products.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  store.commit('toggleLoader', true)
  next()
})
router.afterEach(() => {
  setTimeout(() => {
    store.commit('toggleLoader', false)
  }, 500)
})

export default router
