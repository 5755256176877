<template>
  <div id="app">
    <TheNavBar/>
    <Header v-if="$route.name != 'PrivacyView'"/>
    <router-view/>
    <TheFooter/>
    <TheAlert :alert="alert" v-if="alert && alert.secs > 0"/>
    <TheLoader v-if="isLoading"/>
  </div>
</template>
<script>
// function isPWA() {
//   return window && window.matchMedia("(display-mode: standalone)").matches;
// }
import Header from '@/components/home/Header.vue'
import TheFooter from '@/components/TheFooter.vue'
import TheNavBar from '@/components/TheNavBar.vue'
import TheLoader from '@/components/TheLoader.vue'
import TheAlert from '@/components/TheAlert.vue'
import { mapGetters } from 'vuex'
// import firebase from "./fbConfig";
export default {
  name: 'Main',
  // metaInfo: {
  //   changed(metaInfo) {
  //     firebase.analytics().setCurrentScreen(metaInfo.title);
  //     firebase.analytics().logEvent("page_view");
  //     firebase.analytics().logEvent("screen_view", {
  //       app_name: isPWA() ? "pwa" : "web",
  //       screen_name: metaInfo.title,
  //       app_version: 1.0
  //     });
  //   }
  // },
  components: {
    Header,
    TheAlert,
    TheLoader,
    TheNavBar,
    TheFooter
  },
  data () {
    return {
      noHeader: false
    }
  },
  methods: {
    init () {
      window.addEventListener('scroll', function () {
        var nav = document.getElementById('main-menu')
        if (document.documentElement.scrollTop > 400) {
          nav.classList.add('scrolled')
        } else {
          nav.classList.remove('scrolled')
        }
      })
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapGetters({
      isLoading: 'getLoaderState',
      alert: 'getAlert'
    })
  }
}
</script>
