<template>
  <div class="home">
    <Services/>
  </div>
</template>

<script>
import Services from '@/components/home/Services.vue'

export default {
  name: 'ServicesView',
  components: {
    Services
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
