<template>
  <div class="home">
    <div class="wrapper privacy" id="privacy">
      <div class="content">
        <h1>
           Política de <strong>privacidad</strong>
          <img src="@/assets/images/bulldozer.png">
        </h1>
        <p class="text-left">
          erent.mx, con domicilio en Periférico No. 485, Col. Arcos de Zapopan, CP 45130 Zapopan, Jalisco. Es responsable de recabar sus datos personales, del uso y tratamiento que se le dé a los mismos y de su protección.
        </p>
        <p class="text-left">
          Los datos que les solicitamos serán utilizados para las siguientes finalidades: proveer los servicios y productos que ha solicitado; informarle sobre cambios o nuevos servicios o productos que tengan relación con los ya contratados o adquiridos; elaborar estudios y programas internos sobre hábitos de consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los mismos; y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.
        </p>
        <p class="text-left">
          Para mayor información acerca del tratamiento y de los derechos que puede hacer valer, Ustedes pueden acceder a nuestro aviso de privacidad a través de los siguientes datos de contacto:
          Domicilio: Periférico No. 485, Col. Arcos de Zapopan, CP 45130 Zapopan, Jalisco.
          Correo Electrónico: oms@neoconstructa.com
          Teléfono: 01 (33) 3165 6326
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PrivacyView',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
