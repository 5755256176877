









































import Vue from 'vue'

export default Vue.extend({
  name: 'AboutUs',
  props: {
    msg: String
  }
})
