import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    productsData: null,
    loading: false,
    alert: {
      type: 'info',
      secs: 0,
      msg: 'Set a message'
    }
  },
  mutations: {
    toggleLoader (state, payload) {
      state.loading = payload
    },
    setAlert (state, payload) {
      state.alert.type = payload.type ?? state.alert.type
      state.alert.secs = payload.secs ?? 5000
      state.alert.msg = payload.msg
      setTimeout(() => {
        state.alert.secs = 0
      }, state.alert.secs)
    },
    setProductsData (state, payload) {
      state.productsData = payload
    }
  },
  actions: {
    async sendContactEmail ({ commit }, data) {
      commit('toggleLoader', true)
      await axios.post('https://apis.webrocket.services/v1/erent/contact', data).then(() => {
        commit('setAlert', { msg: 'Hemos recibido tu mensaje, te contactaremos pronto.', type: 'success', secs: 10000 })
      }).catch((error: any) => {
        commit('setAlert', { msg: 'Hubo un error al enviar tu mensaje.', type: 'danger' })
        console.log(error)
      }).finally(() => {
        commit('toggleLoader', false)
      })
    },
    async getProducts ({ commit }) {
      commit('toggleLoader', true)
      await axios.get('https://apis.webrocket.services/v1/erent/catalog', { headers: { 'content-type': 'application/json' } }).then(catalogData => {
        commit('setProductsData', catalogData.data)
      }).catch((error: any) => {
        commit('setAlert', { msg: 'Error al cargar catalogo.', type: 'danger' })
        alert(JSON.stringify(error))
      }).finally(() => {
        commit('toggleLoader', false)
      })
    }
  },
  modules: {
  },
  getters: {
    getLoaderState: state => {
      return state.loading
    },
    getAlert: state => {
      return state.alert
    },
    getProductsData: state => {
      return state.productsData
    }
  }
})
