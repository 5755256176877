<template>
  <div class="home">
    <Products/>
  </div>
</template>

<script>
import Products from '@/components/home/Products.vue'

export default {
  name: 'ProductsView',
  components: {
    Products
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
