<template>
  <div class="home">
    <Contact/>
  </div>
</template>

<script>
import Contact from '@/components/home/Contact.vue'

export default {
  name: 'ContactView',
  components: {
    Contact
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
