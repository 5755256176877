





































































import Vue from 'vue'
import store from '../../store/index'

export default Vue.extend({
  name: 'Contact',
  data () {
    return {
      contact: {
        name: null,
        phone: null,
        email: null,
        message: ''
      }
    }
  },
  methods: {
    sendContactMessage () {
      if (!this.contact.name || !this.contact.phone || !this.contact.email || !this.contact.message) {
        store.commit('setAlert', { msg: 'Por favor llena todos los campos', type: 'warning' })
        return false
      }
      const params = new URLSearchParams()
      params.append('name', `${this.contact.name}`)
      params.append('phone', `${this.contact.phone}`)
      params.append('email', `${this.contact.email}`)
      params.append('description', `${this.contact.message}`)
      store.dispatch('sendContactEmail', params)
      this.contact = {
        name: null,
        phone: null,
        email: null,
        message: ''
      }
    }
  },
  mounted () {
    const pId = this.$route.query.productId
    if (pId !== undefined) {
      this.contact.message = `Que tal, \nHe visto este artículo en tu catálogo y estoy interesado en el: \n- ${pId}. \nMe gustaría obtener una cotización por las siguientes horas: `
      store.commit('setAlert', { msg: 'Por favor completa los datos para que podamos hacer tu cotización.', type: 'success', secs: 15000 })
    }
  }
})
