













import Vue from 'vue'

export default Vue.extend({
  name: 'TheAlert',
  props: {
    alert: Object
  },
  data () {
    return {
      showBottom: true
    }
  }
})
