<template>
  <div class="home">
    <AboutUs/>
  </div>
</template>

<script>
import AboutUs from '@/components/home/AboutUs.vue'

export default {
  name: 'AboutUsView',
  components: {
    AboutUs
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
